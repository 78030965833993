import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import 'leaflet/dist/leaflet.css';
import MappingPage from './Pages/mapping-page';
import MappingPage2 from './Pages/mapping-page-2';
import DashboardPage from './Pages/dashboard-page';
import LoginPage from './Pages/login-page';
import SideMenu from './SideMenu';
import CustomLoginCallback from './CustomLoginCallback'; // Custom LoginCallback component

function App(props) {


  return (

    <BrowserRouter>

        <SideMenu client={props.client} />
        <div>
          <Routes>
            <Route path="/map" element={<MappingPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            
            <Route path="/map2" element={<MappingPage2 />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/callback" element={<CustomLoginCallback />} />
            <Route path="/" element={<DashboardPage />} />
          </Routes>
        </div>
    </BrowserRouter>
  );
}

export default App;
